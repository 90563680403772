import React, { useState } from 'react';
import * as Yup from "yup"
import Dialog from '@mui/material/Dialog';
import {Box, Button, CardContent, FormLabel, Stack, TextField, Typography } from '@mui/material'
import { useSnackbar } from "notistack"
import { useFormik, Form, FormikProvider,getIn} from "formik"
import CustomDropZoneDoc from '../../components/customDropZoneDoc';
import listingServices from '../../services/listingServices';
import authServices from '../../services/authServices';
import { useNavigate } from 'react-router-dom';

export default function UploadMneomonic({open,setOpen, dltAccount, userId, assetId}) {
    const { enqueueSnackbar } = useSnackbar()
    const navigate = useNavigate()

    const SUPPORTED_FORMATS = ["text/plain"]
    const docSchema = Yup.object().shape({
        txt:Yup.mixed().nullable().required("Document Is Required").test("FILE_SIZE", "Uploaded file is too big.", value => value && value.size <= 16384 * 16384).test("FILE_FORMAT", "Uploaded file has unsupported format.", value => value && SUPPORTED_FORMATS.includes(value.type)),
    })
    const formik = useFormik({
        initialValues:{
            txt:null
        },
        validationSchema: docSchema,
        onSubmit: (v) => {
                const reader = new FileReader();
                reader.onload = () => {
                submitEncryptedText({fileContent: reader.result, userId, assetId, dltAccount});
                };
                reader.readAsText(v.txt);
            }
        })
    
        const {errors,touched,values,handleSubmit,getFieldProps,setFieldValue,resetForm} = formik

        
        const onClose=()=>{
            setOpen(false);
            resetForm()
        }

        async function submitEncryptedText(data){
            let res = await authServices.submitEncryptedText(data);
            if(res && res.success){
                enqueueSnackbar(res?.message, { variant: "success",anchorOrigin: { horizontal: "right", vertical: "top"  },autoHideDuration:800})
                resetForm();
                onClose();
                navigate("/dashboard")
            }
            else{
                enqueueSnackbar(res?.data?.message , { variant: "error",anchorOrigin: { horizontal: "right", vertical: "top"  },autoHideDuration:800})
            }
        }
        

  return (
    <Dialog     
    open={open}
    onClose={onClose}
    fullWidth={true}
    maxWidth={'sm'}
    >
       <FormikProvider value={formik}>
            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                <CardContent sx={{margin:'20px 50px'}} >
                    <Stack spacing={3} >
                        <Stack direction={'row'} justifyContent='space-between' alignItems={'center'} >
                            <Typography variant="h4" color={"#2D2F39"}>Upload Encrypted Mneomonic File</Typography>
                        </Stack>
                        <Box>
                            {/* <Typography variant='subHeading'>Upload Transaction Proof</Typography> */}
                            <CustomDropZoneDoc setter={(file) =>{setFieldValue(`txt`,file)}} errors={getIn(touched,`txt`) &&
                                    getIn(errors,`txt`)}
                                    files={values?.txt}
                                    edit={true}
                                    />
                        </Box>
                        <Stack direction={'row'} spacing={1}>
                            <Button type='submit' variant="contained" sx={{ py: 1, px: 3,width:'20%'}}>Submit</Button>
                            <Button variant="outlined" onClick={()=>onClose()} sx={{ py: 1, px: 3,width:'20%'}}>Cancel</Button>
                        </Stack>
                    </Stack>
                </CardContent>
            </Form>
       </FormikProvider>
    </Dialog>
  );
}
