import React, {useState} from "react";
import { Button, Stack, CardContent, Typography, TextField,Grid,Box} from "@mui/material";
import { useFormik, Form, FormikProvider,getIn, Field} from "formik";
import { useSnackbar } from "notistack";
import authServices from '../../services/authServices';
import { useNavigate, useParams } from 'react-router-dom';
import UploadMneomonic from "./uploadMneomonic";
import NoteAddOutlinedIcon from '@mui/icons-material/NoteAddOutlined';
import KeyboardIcon from '@mui/icons-material/Keyboard';

export default function AssetOptin () {
    const { id } = useParams();
    const dltAccount = id.split("&")[0];
    const userId = id.split("&")[1];
    const assetId = +id.split("&")[2];
    const { enqueueSnackbar } = useSnackbar()
    const navigate = useNavigate()

    const [open,setOpen] = useState(false);

    const formik = useFormik({
        initialValues:{
            mneomonic: []
        },
       
        onSubmit: (v) => {
                const mneomonic = [v.mneomonic1, v.mneomonic2,
                    v.mneomonic3, v.mneomonic4, v.mneomonic5,
                    v.mneomonic6, v.mneomonic7, v.mneomonic8,
                    v.mneomonic9, v.mneomonic10, v.mneomonic11,
                    v.mneomonic12, v.mneomonic13, v.mneomonic14,
                    v.mneomonic15, v.mneomonic16, v.mneomonic17,
                    v.mneomonic18, v.mneomonic19, v.mneomonic20,
                    v.mneomonic21, v.mneomonic22, v.mneomonic23,
                    v.mneomonic24, v.mneomonic25
                ];
                sendMneomonic({mneomonic, userId, assetId, dltAccount})
            }
        })
    
        const {errors,touched,values,handleSubmit,getFieldProps,setFieldValue,resetForm} = formik
        
        const onClose=()=>{
            // setOpen(false);
            resetForm()
        }

        async function sendMneomonic (data) {
            let res = await authServices.submitMneomonic(data);
            if(res && res.success){
                enqueueSnackbar(res?.message, { variant: "success",anchorOrigin: { horizontal: "right", vertical: "top"  },autoHideDuration:800})
                resetForm()
                onClose()
                navigate("/dashboard")

            }
            else{
                enqueueSnackbar(res?.data?.message , { variant: "error",anchorOrigin: { horizontal: "right", vertical: "top"  },autoHideDuration:800})
            }
        }

  return (
    <Box sx={{py:5,bgcolor:"#fff",height:{lg:'100vh',xs:'100%',background: 'linear-gradient(to bottom, #FFF 0%,#F2F3F4 100%)'}}}>
        <Stack spacing={1}  sx={{px:{ lg:"200px",md:"80px"}}}>
            <Stack direction={"row"} spacing={"90px"} flex={1} alignItems={"center"}>
               <Box  sx={{height:{md:44,xs:24},cursor:"pointer",ml:{xl:'98px',lg:'40px',md:'40px',sm:'50px',xs:'20px'}}} component={"img"} src={"/assets/images/logo.png"}></Box>
            </Stack>
            <Stack spacing={0} direction={'row'} justifyContent='center' alignItems={'center'} >
               <KeyboardIcon sx={{mr:1}}/>
               <Typography variant="aboutHeading2" color={"#2D2F39"}>Enter Your Mnemonic</Typography>
            </Stack>
            <FormikProvider value={formik}>
                <UploadMneomonic open={open} setOpen={setOpen} dltAccount={dltAccount} userId={userId} assetId={assetId} />
                    <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                 
                    <Stack  spacing={1}  justifyContent={"center"}>
                         
                        <Stack sx={{justifyContent: "center",pt:2, }}>
                        <Grid container spacing={2} sx={{gap: {xl:"2px",lg:"2px",xs:"1px"}, paddingLeft: "0px"}} justifyContent={"center"}>

                         <Grid item lg={2.2} md={2.2} sm={3.5} xs={5.5}>
                         <Field as={TextField} sx={{'& .MuiInputBase-root': { minWidth: 0,padding:"5px 15px !important"} ,minwidth:"50px"}}  name="mneomonic1" type="text" />
                                             
                         </Grid>
                         <Grid item lg={2.2} md={2.2} sm={3.5} xs={5.5}>
                         <Field as={TextField} sx={{'& .MuiInputBase-root': { minWidth: 0,padding:"5px 15px !important"} ,minwidth:"50px"}}  name="mneomonic2" type="text" />

                         </Grid>
                         <Grid item lg={2.2} md={2.2} sm={3.5} xs={5.5}>
                         <Field as={TextField} sx={{'& .MuiInputBase-root': { minWidth: 0,padding:"5px 15px !important"} ,minwidth:"50px"}}  name="mneomonic3" type="text" />

                         </Grid>
                         <Grid item lg={2.2} md={2.2} sm={3.5} xs={5.5}>
                         <Field as={TextField} sx={{'& .MuiInputBase-root': { minWidth: 0,padding:"5px 15px !important"} ,minwidth:"50px"}}  name="mneomonic4" type="text" />

                         </Grid>
                         <Grid item lg={2.2} md={2.2} sm={3.5} xs={5.5}>
                         <Field as={TextField} sx={{'& .MuiInputBase-root': { minWidth: 0,padding:"5px 15px !important"} ,minwidth:"50px"}}  name="mneomonic5" type="text" />

                         </Grid>
 
                         <Grid item lg={2.2} md={2.2} sm={3.5} xs={5.5}>
                         <Field as={TextField} sx={{'& .MuiInputBase-root': { minWidth: 0,padding:"5px 15px !important"} ,minwidth:"50px"}}  name="mneomonic6" type="text" />

                         </Grid>


                         <Grid item lg={2.2} md={2.2} sm={3.5} xs={5.5}>
                         <Field as={TextField} sx={{'& .MuiInputBase-root': { minWidth: 0,padding:"5px 15px !important"} ,minwidth:"50px"}}  name="mneomonic7" type="text" />

                         </Grid>


                         <Grid item lg={2.2} md={2.2} sm={3.5} xs={5.5}>
                         <Field as={TextField} sx={{'& .MuiInputBase-root': { minWidth: 0,padding:"5px 15px !important"} ,minwidth:"50px"}}  name="mneomonic8" type="text" />

                         </Grid>


                         <Grid item lg={2.2} md={2.2} sm={3.5} xs={5.5}>
                         <Field as={TextField} sx={{'& .MuiInputBase-root': { minWidth: 0,padding:"5px 15px !important"} ,minwidth:"50px"}}  name="mneomonic9" type="text" />
                         </Grid>


                         <Grid item lg={2.2} md={2.2} sm={3.5} xs={5.5}>
                         <Field as={TextField} sx={{'& .MuiInputBase-root': { minWidth: 0,padding:"5px 15px !important"} ,minwidth:"50px"}}  name="mneomonic10" type="text" />
                         </Grid>


                         <Grid item lg={2.2} md={2.2} sm={3.5} xs={5.5}>
                         <Field as={TextField} sx={{'& .MuiInputBase-root': { minWidth: 0,padding:"5px 15px !important"} ,minwidth:"50px"}}  name="mneomonic11" type="text" />
                         </Grid>


                         <Grid item lg={2.2} md={2.2} sm={3.5} xs={5.5}>
                         <Field as={TextField} sx={{'& .MuiInputBase-root': { minWidth: 0,padding:"5px 15px !important"} ,minwidth:"50px"}}  name="mneomonic12" type="text" />
                         </Grid>

                         <Grid item lg={2.2} md={2.2} sm={3.5} xs={5.5}>
                         <Field as={TextField} sx={{'& .MuiInputBase-root': { minWidth: 0,padding:"5px 15px !important"} ,minwidth:"50px"}}  name="mneomonic13" type="text" />
                         </Grid>


                         <Grid item lg={2.2} md={2.2} sm={3.5} xs={5.5}>
                         <Field as={TextField} sx={{'& .MuiInputBase-root': { minWidth: 0,padding:"5px 15px !important"} ,minwidth:"50px"}}  name="mneomonic14" type="text" />
                         </Grid>


                         <Grid item lg={2.2} md={2.2} sm={3.5} xs={5.5}>
                         <Field as={TextField} sx={{'& .MuiInputBase-root': { minWidth: 0,padding:"5px 15px !important"} ,minwidth:"50px"}}  name="mneomonic15" type="text" />
                         </Grid>


                         <Grid item lg={2.2} md={2.2} sm={3.5} xs={5.5}>
                         <Field as={TextField} sx={{'& .MuiInputBase-root': { minWidth: 0,padding:"5px 15px !important"} ,minwidth:"50px"}}  name="mneomonic16" type="text" />
                         </Grid>


                         <Grid item lg={2.2} md={2.2} sm={3.5} xs={5.5}>
                         <Field as={TextField} sx={{'& .MuiInputBase-root': { minWidth: 0,padding:"5px 15px !important"} ,minwidth:"50px"}}  name="mneomonic17" type="text" />
                         </Grid>


                         <Grid item lg={2.2} md={2.2} sm={3.5} xs={5.5}>
                         <Field as={TextField} sx={{'& .MuiInputBase-root': { minWidth: 0,padding:"5px 15px !important"} ,minwidth:"50px"}}  name="mneomonic18" type="text" />
                         </Grid>


                         <Grid item lg={2.2} md={2.2} sm={3.5} xs={5.5}>
                         <Field as={TextField} sx={{'& .MuiInputBase-root': { minWidth: 0,padding:"5px 15px !important"} ,minwidth:"50px"}}  name="mneomonic19" type="text" />
                         </Grid>



                         <Grid item lg={2.2} md={2.2} sm={3.5} xs={5.5}>
                         <Field as={TextField} sx={{'& .MuiInputBase-root': { minWidth: 0,padding:"5px 15px !important"} ,minwidth:"50px"}}  name="mneomonic20" type="text" />
                         </Grid>


                         <Grid item lg={2.2} md={2.2} sm={3.5} xs={5.5}>
                         <Field as={TextField} sx={{'& .MuiInputBase-root': { minWidth: 0,padding:"5px 15px !important"} ,minwidth:"50px"}}  name="mneomonic21" type="text" />
                         </Grid>



                         <Grid item lg={2.2} md={2.2} sm={3.5} xs={5.5}>
                         <Field as={TextField} sx={{'& .MuiInputBase-root': { minWidth: 0,padding:"5px 15px !important"} ,minwidth:"50px"}}  name="mneomonic22" type="text" />
                         </Grid>



                         <Grid item lg={2.2} md={2.2} sm={3.5} xs={5.5}>
                         <Field as={TextField} sx={{'& .MuiInputBase-root': { minWidth: 0,padding:"5px 15px !important"} ,minwidth:"50px"}}  name="mneomonic23" type="text" />
                         </Grid>


                         <Grid item lg={2.2} md={2.2} sm={3.5} xs={5.5}>
                         <Field as={TextField} sx={{'& .MuiInputBase-root': { minWidth: 0,padding:"5px 15px !important"} ,minwidth:"50px"}}  name="mneomonic24" type="text" />
                         </Grid>

                         <Grid item lg={2.2} md={2.2} sm={3.5} xs={5.5}>
                         <Field as={TextField} sx={{'& .MuiInputBase-root': { minWidth: 0,padding:"5px 15px !important"} ,minwidth:"50px"}}  name="mneomonic25" type="text" />
                         </Grid>

                        </Grid>
                        </Stack>



                            {/* <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'} >
                                <TextField className=" custom-input" name="mneomonic1" type="text" />
                                <TextField className=" custom-input" name="mneomonic2" type="text" />
                                <TextField className="custom-input" name="mneomonic3" type="text" />
                                <TextField className="form-control"  name="mneomonic4" type="text" />
                                <TextField className="form-control"  name="mneomonic5" type="text" />  
                             </Stack>
                            <Stack direction={'row'} justifyContent='space-between' alignItems={'center'} >
                                <Field className="form-control" name="mneomonic6" type="text" />
                                <Field className="form-control" name="mneomonic7" type="text" />
                                <Field className="form-control" name="mneomonic8" type="text" />
                                <Field className="form-control" name="mneomonic9" type="text" />
                                <Field className="form-control" name="mneomonic10" type="text" />
                            </Stack>
                            <Stack direction={'row'} justifyContent='space-between' alignItems={'center'} >
                                <Field className="form-control" name="mneomonic11" type="text" />
                                <Field className="form-control" name="mneomonic12" type="text" />
                                <Field className="form-control" name="mneomonic13" type="text" />
                                <Field className="form-control" name="mneomonic14" type="text" />
                                <Field className="form-control" name="mneomonic15" type="text" />
                            </Stack>
                            <Stack direction={'row'} justifyContent='space-between' alignItems={'center'} >
                                <Field className="form-control" name="mneomonic16" type="text" />
                                <Field className="form-control" name="mneomonic17" type="text" />
                                <Field className="form-control" name="mneomonic18" type="text" />
                                <Field className="form-control" name="mneomonic19" type="text" />
                                <Field className="form-control" name="mneomonic20" type="text" />
                            </Stack>
                            <Stack direction={'row'} justifyContent='space-between' alignItems={'center'} >
                                <Field className="form-control" name="mneomonic21" type="text" />
                                <Field className="form-control" name="mneomonic22" type="text" />
                                <Field className="form-control" name="mneomonic23" type="text" />
                                <Field className="form-control" name="mneomonic24" type="text" />
                                <Field className="form-control" name="mneomonic25" type="text" />
                            </Stack>      */}


                      

                        <Stack direction={'row'} sx={{pt:'14px'}} justifyContent='center' alignItems={'center'} spacing={1}>
                            <Button type='submit' variant="outlined" >Submit</Button>
                            <Button  variant="outlined"  onClick={()=>onClose()}>Cancel</Button>

                        </Stack>


                    </Stack>
                    
                
            </Form>
           </FormikProvider>
            <Stack flexDirection={"row"} justifyContent={"center"}>
                <Box sx={{my:3,width:"35%",height:1.2,bgcolor:"#000"}} />
                <Box sx={{m:1.5, fontSize: '1.2rem',color:"#000",fontFamily:"tiko"}}>OR</Box>
                <Box sx={{my:3,width:"35%",height:1.2,bgcolor:"#000"}} />
            </Stack>
            <Stack sx={{}} direction={"row"} justifyContent={"center"}>       
                <Button onClick={()=>setOpen(true)} variant="contained" sx={{px:3,}}>
                <NoteAddOutlinedIcon sx={{mr:0.5}}/>
                <Typography sx={{ml:0.5}}>Upload Mnemonic File</Typography>
                </Button>
            </Stack>
        </Stack>
    </Box>
  )
}