import React,{ useEffect, useState} from 'react'
import { Box, Button, Stack, Typography,Popover } from "@mui/material";
import LoginModal from "../modals/login";
// import { useSnackbar } from 'notistack';
import { useLocation, useNavigate } from 'react-router-dom';
// import { useSelector } from 'react-redux';
import MenuIcon from '@mui/icons-material/Menu';
import MainSideDrawer from './mainSideDrawer';
import CustomTabs from '../tabs/customTabs';

const MainHeader = () =>{

    const [showLogin,setShowLogin] = useState(false);
    // const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();
    // const user = useSelector(state => state.user.data);
    const [transparentHeader,setTransparentHeader] = useState(true);
    const [openDrawer,setOpenDrawer] = useState(false);
    const [selectedTab,setSelectedTab] = useState(-1);
    const [showTerms,setShowTerms] = useState(false);
    const[exploresubmenuAnchorEl,setexploreSubmenuAnchorEl]= useState(null);
    // const [showSignUp,setShowSignUp] = useState(false);

    const { pathname } = useLocation();

    const ExploresubmenuOptions=[
        {
            name: "How It works",
            onClick: () => window.open('https://www.realx.in/how-it-works', '_blank')
        },
        {
            name: "Why RealX",
            onClick: () => window.open("https://www.realx.in/why-realx", '_blank')
        },
        {
            name: "About Us",
            onClick: () => window.open("https://www.realx.in/about-us", '_blank')
        },
      ]

      const exploreSubmenuClick=(event)=>{
        setexploreSubmenuAnchorEl(event.currentTarget)
    }
    
      const exploreSubmenuClose = () => {
        setexploreSubmenuAnchorEl(null);
      };
    
    

    useEffect(()=>{
        if(pathname !== "/"){
            setTransparentHeader(false);
        }
        else{
            setTransparentHeader(true);
        }
        window.addEventListener("scroll",checkScroll);

        return ()=>window.removeEventListener("scroll",checkScroll);
        // eslint-disable-next-line
    },[pathname]);

    useEffect(()=>{
        console.log(pathname,"----->Current route");
        console.log(tabs.findIndex((e)=>pathname.includes(e.link)),"----->Current tab");
        const index = tabs.findIndex((e)=>pathname.includes(e.link));
        if(index >= 0){
            setSelectedTab(index);
        }
        else{
            setSelectedTab(-1);
        }
     // eslint-disable-next-line
    },[pathname]);

    const checkScroll = () =>{
        // console.log(window.innerHeight,);
        if(window.scrollY>window.innerHeight){
            setTransparentHeader(false);
        }
        else{
            if(pathname === "/"){
                setTransparentHeader(true);
            }
        }
    } 

    const handleClick = (link) =>{
        
        if (link.includes("https")) {
            window.open(link, '_blank');
        }
        else {
            navigate(link);
        }
    }

    const handleTabClick = (index) =>{
        setSelectedTab(index);
        if(tabs[index]){
            handleClick(tabs[index].link);
        }
    }

    const tabs = [
      
        {
            name:"FAQs",
            link:"https://www.realx.in/faq"
        },
      
    ]

    return(
        <Box 
            sx={{
                // height:"100px",
                width:"100%",
                // maxWidth:"1920px",
                // position:"fixed",
                left:0,
                display:"block",
                position:"fixed",
                top:transparentHeader?-84:0,
                bgcolor:"#FFF",
                transition:"all 0.25s ease",
                
                zIndex:2,
                boxShadow:transparentHeader?"none":"#00000008 0px 1px 10px"
            }}
        > 
        <LoginModal handleClose={()=>setShowLogin(false)} open={showLogin} showTerms={showTerms} setShowTerms={setShowTerms}  />
        <MainSideDrawer open={openDrawer} handleClose={()=>setOpenDrawer(false)} />
        <Box
            sx={{
                px:{lg:17,md:8,xs:4},
                py:2.5,
                maxWidth:"1920px",
                margin:"auto",
                bgcolor:"#FFF",
            }}
        >
            <Stack direction={"row"} spacing={5}>
                <Stack direction={"row"} spacing={"90px"} flex={1} alignItems={"center"}>
                    <Box onClick={()=>handleClick('https://www.realx.in/')} sx={{height:{md:44,xs:24},cursor:"pointer"}} component={"img"} src={"/assets/images/logo.png"}></Box>
                </Stack>
                <CustomTabs onClick={(index)=>handleTabClick(index)} tabs={tabs} props={{alignItems:"center",spacing:7,display:{md:"flex",xs:"none"}}} labelProps={{varient:"headerTab",sx:{fontWeight:600}}} activeTab={selectedTab} />
                {/* <Box pl={7} pt={1} sx={{display:{md:"flex",xs:"none"}}}>
                    <Typography onClick={()=>setShowLogin(true)} variant="body1" sx={{fontWeight:600,cursor:"pointer"}}>Login</Typography>
                </Box> */}
                
                {/* explore realx popover */}
                <Box pt={1} sx={{display:{md:"flex",xs:"none"}}}>
                <Typography onClick={exploreSubmenuClick} variant="body1" sx={{fontWeight:600,cursor:"pointer"}}>Explore RealX</Typography>
                 <Popover
                                open={Boolean(exploresubmenuAnchorEl)}
                                    anchorEl={exploresubmenuAnchorEl}
                                    onClose={exploreSubmenuClose}
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'left',
                                    }}
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'left',
                                    }}
                                >
                                    <Box p={2}>
                                        {ExploresubmenuOptions.map((option, index) => (
                                            <Typography
                                                key={index}
                                                variant="body1"
                                                sx={{ fontWeight: 600, cursor: "pointer", padding: "5px", fontWeight: "Normal" }}
                                                onClick={() => {
                                                    exploreSubmenuClose();
                                                    option.onClick(); // Execute the dynamic function
                                                }}
                                            >
                                                {option.name}
                                            </Typography>
                                        ))}
                                    </Box>

                                </Popover>
                </Box>
                <Button onClick={()=>setShowLogin(true)} variant="contained" sx={{color:"#fff",borderColor:"#252525",ml:7,display:{md:"block",xs:"none"}}}>
                    <Typography  variant="body1" sx={{fontWeight:600,cursor:"pointer",lineHeight:1}}>Login / Signup</Typography>
                </Button>
                <MenuIcon onClick={()=>setOpenDrawer(true)} sx={{cursor:"pointer",display:{md:"none",xs:"block"}}} fontSize="medium" />
            </Stack>
        </Box>
        {/* <AccountSetupModal handleClose={()=>setShowSignUp(false)} open={showSignUp}  /> */}
        </Box>
    )
}
export default MainHeader