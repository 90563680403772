import React,{useEffect, useState} from 'react'
import { Box, Button, FormHelperText, Stack, Typography } from '@mui/material';
import {useDropzone} from 'react-dropzone'
import Axios from 'axios'
import fileDownload from 'js-file-download'

const CustomDropZoneDoc = ({setter,title,errors,files,edit,width,url}) =>{

    const [file,setFile] = useState(files)
    const {getRootProps, getInputProps} = useDropzone({
        onDrop: acceptedFiles => {
            let d = (acceptedFiles.map(file => Object.assign(file, {preview: URL.createObjectURL(file)})))
            setFile(d[0])
            setter(d[0])
        },
        maxFiles:1
    });
    useEffect(()=>{
        setFile(files);
    },[files]);
    function download(url, filename) {
        Axios.get(url, {
          responseType: 'blob',
        }).then(res => {
          fileDownload(res.data, filename);
        });
      }


    return(
        <Stack spacing={1} >
            <Typography sx={{fontSize:12,color:'#818E94'}}>{title}</Typography>
            <Stack direction={'row'} spacing={1} >
            <Box {...getRootProps({className: 'dropzone',onClick: event => !edit ? event.stopPropagation():""})} sx={{display:'flex',justifyContent:'center',alignItems:'center',width:width? width : '70%',height:75,
                    borderRadius:'8px',border:'1px dashed #C6CDD5',backgroundColor:'#F8F8F8',padding:'10px','&:hover':{cursor:'pointer'}}} >
                    <input {...getInputProps()}  />
                    <Typography>{(file?.name?.split('圓')[1]) ? (file?.name?.split('圓')[1]) : file?.name.substring(0,80)}</Typography>
            </Box>
            <FormHelperText sx={{color:'red'}}>{errors}</FormHelperText>
            {
                url ? 
                <Button disabled={files == null} variant="contained" 
                onClick={()=>download(url,file?.name?.split('圓')[1])}
                // onClick={()=> window.open(url)}
                >Download</Button>
                :""
            }
            </Stack>
        </Stack>
    )
}
export default CustomDropZoneDoc