import { Box, Divider, LinearProgress, Stack, Typography, Grid,Paper, Icon } from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { updateWatchlist } from "../redux/reducers/userSlice";
import userServices from "../services/userServices";
import { calcTarget, calculatePercent, convertToIndianFormat, isInWatchlist } from "../utils/functions";
import CustomIcon from "./customIcon";
import ShareIcon from '@mui/icons-material/Share';
import DomainIcon from '@mui/icons-material/Domain';
import ApartmentIcon from '@mui/icons-material/Apartment';
import HomeWorkRoundedIcon from '@mui/icons-material/HomeWorkRounded';
import CurrencyRupeeRoundedIcon from '@mui/icons-material/CurrencyRupeeRounded';
import GridViewOutlinedIcon from '@mui/icons-material/GridViewOutlined';
export default function PropertyCard({data,url}){
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const { watchlist } = useSelector(state=>state.user);
    const [inWatchlist,setInWatchlist] = useState(false);
    const [ratePerFrax,setRatePerFrax]= useState(0);
    const [totalCost,setTotalCost] = useState(0);
    const [noOfFrax,setNoOfFrax]= useState(0);
    const [noOfRemFrax,setNoOfRemFrax]= useState(0);
    const [target,setTarget] = useState(0);
    const [selectPropertyLaebl,shwoSelectPropertyLabel] = useState(false);

    const handleCardClick = () =>{
        navigate(`/dashboard/property/${data?._id}`,{state:{id:data?._id}})
    }

    useEffect(()=>{
        if(data){
            setInWatchlist(isInWatchlist(data?._id));
            setNoOfFrax(data?.numberOfFracs);
            setNoOfRemFrax(data?.remainingFracs);
            setTotalCost(Math.round(data?.transactionDetails?.totalCostOfPurchase))
            setRatePerFrax((data?.transactionDetails?.totalCostOfPurchase)/(144 * data?.characteristics?.builtupArea));
            shwoSelectPropertyLabel(data?.membertype === "SELECT"? true: false);
            
        }
    },[data,watchlist])


    const getTitle = () =>{
        if(data?.propertyType === "Residential") return `${data?.basicInfo?.flatNo}, Wing-${data?.basicInfo?.wing} in ${data?.project?.projectName} - ${data?.project?.location?.city}`
        if(data?.propertyType === "Commercial" || data?.propertyType === "Retail") return `${data?.basicInfo?.unitNo}, Wing-${data?.basicInfo?.wing} in ${data?.project?.projectName} - ${data?.project?.location?.city}`
        if(data?.propertyType === "Land") return `Plot No. ${data?.basicInfo?.plotNo} in ${data?.project?.projectName} - ${data?.project?.location?.city}`
        if(data?.propertyType === "Warehouse") return `Warehouse No. ${data?.basicInfo?.warehouseNo} in ${data?.project?.projectName} - ${data?.project?.location?.city}`
        return "";
    }

    const getDescription = () =>{
        //  return `${data?.propertyType}, ${data?.tenancyType} data.propertyStage.constructedWithOC? 'Constructed With OC': ''`}
        return `${data?.propertyType}, ${data?.tenancyType}, ${data?.propertyStage?.constructedWithOC ? 'Constructed With OC' : ''}`;

    }

    const toggleWatchlist = async() =>{
        const res = await userServices.toggleWatchlist(data?._id);
        if(res && res.success){
            getMyWatchlist();
        }
    }

    const getMyWatchlist = async()=>{
        const res = await userServices.getMyWatchlist();
        if(res && res.success && res.data?.length>0){
            console.log("updating");
            enqueueSnackbar(inWatchlist?"Removed from watchlist":"Added in watchlist",{variant:"success"})
            dispatch(updateWatchlist(res.data));
        }
        else{
            enqueueSnackbar(inWatchlist?"Removed from watchlist":"Added in watchlist",{variant:"success"})
            dispatch(updateWatchlist([]));
        }
    }

    useEffect(()=>{
        let temp = calcTarget(noOfFrax,noOfRemFrax,ratePerFrax);
        if(temp != null) setTarget(temp)
    },[noOfFrax,noOfRemFrax,ratePerFrax])

    const Tdata = [
        { icon: 'DomainIcon', text: 'Time', value: '10:00 AM' },
        { icon: 'attach_money', text: 'Price', value: '$100' },
        { icon: 'people', text: 'Guests', value: '4' },
        { icon: 'check_circle', text: 'Status', value: 'Confirmed' },
        // Add more data objects for the second row
      ];
      const anualYield = (data?.tenancyDetails?.rentPerMonth*12 )/data?.transactionDetails?.salePrice;  
    
      function onError(e) {
        e.target.src =
          "assets/icons/poweredby.png";
      }
      
    return(
        <Stack
            sx={{
               borderRadius:"10px",
               width:{md:398,xs:"auto"},
               maxWidth: '100%',
               height:{md:636,xs:500},
               cursor:"pointer"
            }}
            className="property-card"
            onClick={handleCardClick}
        >
            <Box
                sx={{
                    width:"100%",
                    height:"49.845%",
                    bgcolor:"purple",
                    borderRadius:"10px 10px 0px 0px",
                    // background:"url('https://images.pexels.com/photos/290275/pexels-photo-290275.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1')",
                    background:`url('${url}${data?.listingImages[0]?.docUrl}')`,
                    backgroundSize:"cover",
                    backgroundRepeat:"no-repeat",
                    backgroundPosition:"center",
                    position:"relative",
                }}
            >


                <Grid style={{
                     position: 'absolute',
                     left: '0',
                     top: '0',
                     zIndex: '1',
                     display: selectPropertyLaebl ? 'block' : 'none'
                }}>
                    <img src="/assets/images/select_banner.png" style={{ height: "109px", width: '115px'}} alt="Description of the image" />
                </Grid>

                <Stack 
                    direction={"column"}
                    justifyContent={"space-between"}
                    sx={{
                        position:"absolute",
                        width:"100%",
                        height:"100%",
                        // background: "transparent linear-gradient(180deg, #0000001A 0%, #141E37CC 100%) 0% 0% no-repeat padding-box",
                        borderRadius: "10px 10px 0px 0px",
                        px:{md:2,xs:2},
                        pt:{md:2,xs:3},
                        pb:{md:2,xs:3},
                    }}
                >
                    <Stack direction={"row"} justifyContent={"space-between"}>
                        {/* <Box 
                             sx={{
                                borderRadius:"18px",
                                padding:"8px 24px",
                                display:"flex",
                                justifyContent:"center",
                                alignItems:"center",
                                width:"fit-content",
                                position:"relative",
                                background:"inherit",
                                overflow: "hidden",
                                "&::before":{
                                    content:"''",
                                    position: "absolute",
                                    width:"100%",
                                    height:"100%",
                                    background: "inherit",
                                    borderRadius: "18px",
                                    boxShadow: "inset 0 0 0 200px rgb(255,255,255)",
                                    filter: "blur(50px) brightness(12%)",
                                    // "-webkit-backdrop-filter": "blur(20px) brightness(88%)",
                                    zIndex:0
                                }
                            }}
                        >
                            <Typography variant="chipText" sx={{color:"#FFF",zIndex:1,position:"relative"}}>{data?.project?.location?.city}</Typography>
                        </Box> */}
                            {/* <ShareIcon sx={{color:"#FFF"}} /> */}

<Box 
                            onClick={(e)=>{
                                e.stopPropagation();
                                e.preventDefault();
                                toggleWatchlist();
                            }}
                            sx={{
                                borderRadius:"100%",
                                // background:"#ffffff33 0% 0% no-repeat padding-box",
                                width:36,
                                height:36,
                                display:"flex",
                                justifyContent:"center",
                                alignItems:"center",
                                padding:0.5,
                                position:"relative",
                                overflow: "hidden",
                                "&::before":{
                                    position: "absolute",
                                    width:"100%",
                                    height:"100%",
                                    borderRadius: "18px",
                                    background: "inherit",
                                    boxShadow: "inset 0 0 0 200px rgb(255,255,255)",
                                    filter: "blur(50px) brightness(12%)",
                                    zIndex:0
                                }
                            }}
                        >
                            {/* <CircleIcon sx={{width:"100%",height:"100%",color:"#FFF",zIndex:1,position:"relative"}} /> */}
                        </Box>

                        <Box 
                            onClick={(e)=>{
                                e.stopPropagation();
                                e.preventDefault();
                                toggleWatchlist();
                            }}
                            sx={{
                                borderRadius:"100%",
                                // background:"#ffffff33 0% 0% no-repeat padding-box",
                                width:36,
                                height:36,
                                display:"flex",
                                justifyContent:"center",
                                alignItems:"center",
                                padding:0.5,
                                position:"relative",
                                background:inWatchlist?"white":"inherit",
                                overflow: "hidden",
                                "&::before":{
                                    content:inWatchlist?null:"''",
                                    position: "absolute",
                                    width:"100%",
                                    height:"100%",
                                    borderRadius: "18px",
                                    background: "inherit",
                                    boxShadow: "inset 0 0 0 200px rgb(255,255,255)",
                                    filter: "blur(50px) brightness(12%)",
                                    zIndex:0
                                }
                            }}
                        >
                            {/* <CircleIcon sx={{width:"100%",height:"100%",color:"#FFF",zIndex:1,position:"relative"}} /> */}
                            <CustomIcon name={inWatchlist?"heart-filled":"heart"} sx={{bgcolor:inWatchlist?"#ff3d6b":"#FFF"}} />
                        </Box>
                    </Stack>
                </Stack>
            </Box>
            <Stack direction={"column"} className="property-card-wrapper"
                sx={{
                    px:{md:2,xs:2},
                    pt:{md:1,xs:2},
                    pb:1,
                    flex:1,
                    borderRadius:"0px 0px 10px 10px",
                    border:"1px solid",
                    borderColor:"divider"
                }}
            >
                    {/* <Box
                        sx={{
                            borderRadius:"18px",
                            bgcolor:"primary.main",
                            padding:"8px 24px",
                            display:"flex",
                            justifyContent:"center",
                            alignItems:"center",
                            color:"#FFF",
                            width:"fit-content",
                            
                        }}
                    >
                        <Typography variant="chipText" sx={{color:"#FFF"}}>{data?.propertyType}</Typography>
                    </Box> */}
                <Grid container spacing={2}>
                    
                            <Grid item xs={4}>
                                <div style={{ textAlign: 'center'}}>
                                <img src="assets/icons/builder.png" alt="Image 1" style={{ width: 'auto',
                                    height: '2.5rem',
                                    minHeight: '2.5rem',
                                    maxHeight: '2.5rem',
                                    maxWidth: '100%',
                                    overflow: 'hidden',
                                    display: 'block',
                                    margin: 'auto',
                                    marginBottom: '5px',}} 
                                />
                                <Typography variant="subtitle1">Developer</Typography>
                                </div>
                            </Grid>
                    <Grid item xs={4} style={{zIndex: '20'}}>

                            {data?.isBranded && 
                        <div style={{ textAlign: 'center'}}>
                        <img 
                            src={`${url}${data?.created_by?.logo}`} 
                            onError={(e) => onError(e)}
                            lt="Image 1" 
                            style=
                                {{ width: '5.5rem',
                                    height: '5.5rem',
                                    minHeight: '5.5rem',
                                    maxHeight: '5.5rem',
                                    maxWidth: '5.5rem',
                                    overflow: 'hidden',
                                    display: 'block',
                                    margin: 'auto',
                                    borderRadius: '50%',
                                    backgroundColor: 'white',
                                    marginTop: "-55px",
                                    marginBottom: '5px'
                                }} 
                            />
                            <Typography variant="subtitle1" style={{fontWeight: "bold", color: "#333"}}>{(data?.created_by?.name?.slice(0, 1)?.toUpperCase() + data?.created_by?.name?.slice(1, data?.created_by?.name?.length)?.toLowerCase()) || ""}</Typography>
                            <Typography variant="subtitle1">Brought to you by</Typography>
                        </div>
                        }

                        { !data?.isBranded && 
                                        <div style={{ textAlign: 'center'}}>
                                        <img src="assets/icons/tenant.png" alt="Image 1" style={{ width: 'auto',
                                            height: '2.5rem',
                                            maxHeight: '2.5rem',
                                            minHeight: '2.5rem',
                                            maxWidth: '100%',
                                            overflow: 'hidden',
                                            display: 'block',
                                            margin: 'auto',
                                            marginBottom: '5px',}} />
                                        <Typography variant="subtitle1">Tenant</Typography>
                                        </div>

                        }
                    </Grid>

                    <Grid item xs={4}>
                        <div style={{ textAlign: 'center'}}>
                        <img src="assets/icons/tenant.png" alt="Image 1" style={{ width: 'auto',
                            height: '2.5rem',
                            maxHeight: '2.5rem',
                            minHeight: '2.5rem',
                            maxWidth: '100%',
                            overflow: 'hidden',
                            display: 'block',
                            margin: 'auto',
                            marginBottom: '5px',}} />
                        <Typography variant="subtitle1">Tenant</Typography>
                        </div>
                    </Grid>
                </Grid>


      
                    <Typography noWrap sx={{fontSize:22,fontWeight:500,mt:1,textOverflow:"ellipsis"}}>{getTitle()}</Typography>
                    <Typography  variant="body2" sx={{color: '#333333'}}>{getDescription()}</Typography>

                    <Grid container spacing={0.5} sx={{my: 1}}>

                            <Grid xs={6} sx={{textAlign: "left"}}>
                        <Typography variant="subtitle1">Selling Price per FRAX</Typography>

                    <Typography variant="h4" sx={{ color: '#333333'}}>
                    ₹ {convertToIndianFormat((data?.transactionDetails?.salePrice/(data?.characteristics?.builtupArea * 144)).toFixed(2))}
                    <span style={{ fontSize: '0.75rem', marginLeft: '4px' }}></span>
                    </Typography>
                    </Grid>

                    <Grid xs={6} sx={{textAlign: "right"}}>
                    <Typography variant="subtitle1">Selling Price per SQFT</Typography>
                    <Typography variant="h4" sx={{ color: '#333333'}}>
                                        ₹ {convertToIndianFormat(Math.round(data?.transactionDetails?.salePrice / data?.characteristics?.builtupArea))|| "-"}
                    <span style={{ fontSize: '0.75rem', marginLeft: '4px' }}></span>
                    </Typography>
                </Grid>
                    </Grid>




                    {/* <Divider sx={{my:{md:3,xs:2}, marginTop: '14px', marginBottom: '14px'}} /> */}

                    {/* <div>
                      {data?.highlights.map((item, index) => (<div key={index} dangerouslySetInnerHTML={{ __html: item.highlightValue }}/>)}
                    </div> */}


                    <Grid container spacing={2} sx={{my: 1}} className="property-characteristics">

                    <Grid xs={4} sx={{textAlign: "center"}}>
                        <DomainIcon color="action" fontSize="small"/>
                        <Typography variant="subtitle1">BuiltUp Area</Typography>
                            <Typography variant="body2">{data?.characteristics?.builtupArea} sqft</Typography>
                    </Grid>

                    {data.tenancyType !== "Vacant" ? (
                    <Grid xs={4} sx={{textAlign: "center"}}>
                        <HomeWorkRoundedIcon color="action" fontSize="small"/>
                        <Typography variant="subtitle1">Rental Yield</Typography>
                            <Typography variant="body2">{anualYield} %</Typography>
                    </Grid>
                    ): (

                    <Grid xs={4} sx={{textAlign: "center"}}>
                        <HomeWorkRoundedIcon color="action" fontSize="small"/>
                        {/* <Typography variant="subtitle1">Carpet Area</Typography> */}
                        <Typography variant="subtitle1">Total FRAX</Typography>
                            <Typography variant="body2">{data?.numberOfFracs}</Typography>
                            {/* <Typography variant="body2">{data?.characteristics?.carpetArea} sqft</Typography> */}
                    </Grid>
                    )}

                    {/* Sales Price icon and info -  Keeping commened for future use*/}
                    {/* <Grid xs={3} sx={{textAlign: "center"}}>
                    <GridViewOutlinedIcon color="action" fontSize="small"/>
                        <Typography variant="subtitle1">Selling Price</Typography>
                            <Typography variant="body2">
                            ₹ {data?.transactionDetails?.salePricePSF.toFixed(0)}
                                </Typography>
                    </Grid> */}
                    <Grid xs={4} sx={{textAlign: "center"}}>
                    <CurrencyRupeeRoundedIcon color="action" fontSize="small"/>
                        <Typography variant="subtitle1">Min Investment</Typography>
                            <Typography variant="body2">₹ {data?.transactionDetails?.minInvestmentAmount.toFixed(0)}</Typography>
                    </Grid>




                    </Grid>


                    <Grid>

                    <Stack direction={"column"} spacing={2} sx={{marginTop: 1}}>
                        {/* <Stack direction={"row"} justifyContent={"space-between"}>
                            <Typography sx={{fontSize:16,fontWeight:400}}>Min Investment</Typography>
                            <Typography sx={{fontSize:16,fontWeight:500}}>{(data?.transactionDetails?.minInvestmentAmount)?.toLocaleString('en-IN')}</Typography>
                        </Stack> */}
                        <Stack direction={"row"} justifyContent={"space-between"}>
                            <Typography variant="body2">Invested</Typography>
                            <Typography >{(calculatePercent(target,totalCost) || 0).toFixed(2)}%</Typography>
                        </Stack>
                    </Stack>
                    <LinearProgress color="success" variant="determinate" value={calculatePercent(target,totalCost) || 0} sx={{mt:1}} />
                    <Typography variant="subtitle1" sx={{fontSize:14,fontWeight:400,mt:{md:2,xs:1.5}}}>{convertToIndianFormat(target) || 0} / {convertToIndianFormat(totalCost)}</Typography>
            
                    </Grid>

   
            
            </Stack>


        </Stack>
    )
}