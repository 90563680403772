import { Stack, TextField, Typography } from "@mui/material";
import { DatePicker,LocalizationProvider  } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment from "moment";
import React from "react";

export default function FormDatePicker({label,props,labelVarient="label"}){
    return(
        <Stack direction={"column"} spacing={1}>
            <Typography variant={labelVarient}>{label}</Typography>
            <LocalizationProvider dateAdapter={AdapterMoment}>
            <DatePicker
                // value={value}
                views={["year","month","day"]}
                onChange={()=>{}}
                {...props}
                minDate={moment().subtract(100, "years")}
                maxDate={moment().subtract(18, "years")}
                inputFormat={"DD/MM/YYYY"}
                renderInput={(params) => <TextField {...params} />}
            />
        </LocalizationProvider>
        </Stack>
        
    )
}